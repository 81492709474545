import dayjs from "dayjs";
import { supabase } from "../supabaseClient";

export const adjustTime = (datetime) => {
  return dayjs(datetime).format("YYYY-MM-DD HH:mm:ss");
};

export const adminUserIds = [
  "8561c677-63c0-4abf-8916-798ddd18ec14",
  "ecd42b31-a1de-4fbf-a8f1-35b2998b88db",
  "98146fef-eba8-43e6-b400-c05fb96b526e",
];

export const getPublicImageUrl = (filename) => {
  if (!filename || filename.includes("https://")) {
    return filename;
  }

  const { data } = supabase.storage.from("images").getPublicUrl(filename);

  return data.publicUrl;
};

export const defaultIcons = [
  "heroicons:shopping-bag",
  "heroicons:gift",
  "heroicons:ticket",
  "heroicons:cake",
  "heroicons:heart",
  "heroicons:sparkles",
  "heroicons:currency-dollar",
  "heroicons:tag",
  "heroicons:star",
  "heroicons:fire",
  "heroicons:light-bulb",
  "heroicons:puzzle-piece",
  "heroicons:musical-note",
  "heroicons:camera",
  "heroicons:film",
  "heroicons:book-open",
  "heroicons:academic-cap",
  "heroicons:briefcase",
  "heroicons:truck",
  "heroicons:building-storefront",
];

export const baseUrl =
  process.env.NODE_ENV === "production"
    ? `https://22night.com`
    : "http://localhost:3000";

export const availableGenres = [
  "House",
  "Afro",
  "Classics",
  "Disco",
  "Hard-House",
  "Hits",
  "Latin",
  "Techno",
  "RnB",
  "HipHop",
  "Trance",
  "Urban",
  "Amapiano",
];

export const convertToUTC = (datetime) => {
  const originalDate = new Date(datetime);
  const utcDatetime = new Date(
    originalDate.getTime() - originalDate.getTimezoneOffset() * 60000
  );
  const formattedUtcDatetime = utcDatetime.toISOString();
  return formattedUtcDatetime;
};
