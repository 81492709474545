import React from "react";

import Handshake from "../assets/handshake.png";

export default function EventMemberInfo({
  eventSignups,
  event,
  handleSubmit,
  soldOutStatus,
  btnDisabled,
  isDeal = false,
}) {
  return (
    <div className="z-[10000] flex items-center flex-col social-proof-card py-[24px] px-[16px] mx-6 lg:mt-20 mt-12 max-w-[95vw] lg:w-[400px]">
      <h4 className="text-[22px] font-semibold text-white text-center w-full">
        {isDeal
          ? "Lifestyle Deals"
          : event.category === "Community ticket"
          ? "Community Tickets"
          : "Guestlist Spots"}
      </h4>
      <img src={Handshake} className="my-[16px] w-[60%] h-auto" />
      {!isDeal && (
        <>
          <h3 className="!mt-8 text-[32px] font-semibold text-purple-300 leading-[20px] pb-0">
            {eventSignups}
          </h3>
          <p className="text-center mt-4 text-[18px] text-white">
            {event.category === "Community ticket"
              ? "Members hebben een ticket gekocht"
              : "Members staan op de gastenlijst"}
          </p>
        </>
      )}
      {/* <div className="flex gap-[-20px] justify-center mt-4">
        <img src={Person5} className="h-[32px] w-[32px] ml-[-10px]" />
        <img src={Person1} className="h-[32px] w-[32px] ml-[-10px]" />
        <img src={Person2} className="h-[32px] w-[32px] ml-[-10px]" />
        <img src={Person3} className="h-[32px] w-[32px] ml-[-10px]" />
        <img src={Person4} className="h-[32px] w-[32px] ml-[-10px]" />
      </div> */}
      <div className="max-lg:hidden w-full">
        <hr className="w-full max-lg:hidden !my-[24px]  !border-gray-800" />
        <div className="flex justify-between w-full items-center">
          <h3 className="!pb-0 text-[18px] font-semibold text-white">Totaal</h3>
          <h3 className="!pb-0 text-[22px] font-semibold text-white">
            {event.currency}{isDeal ? event.our_price : event.our_price.toFixed(2)}
          </h3>
        </div>
        <div className="flex justify-between w-full items-center">
          <h3 className="text-[16px] font-semibold text-neutral-300">
            Originele ticket prijs
          </h3>
          <h3 className="text-[16px] line-through font-semibold text-neutral-300">
            {event.currency}{isDeal ? event.original_price : event.original_price.toFixed(2)}
          </h3>
        </div>
        <div className="price-button w-full ">
          <button
            className="mt-[16px] !w-full button openPopupButtons !text-[17px] !py-[24px] !px-[30px] premium-button"
            type="button"
            onClick={handleSubmit}
            disabled={
              soldOutStatus === true || btnDisabled === true ? "disabled" : ""
            }
          >
            {isDeal
              ? "Claim deal"
              : event.category === "Community ticket"
              ? "Claim ticket"
              : "Claim Guestlist Spot"}
          </button>
        </div>
      </div>
    </div>
  );
}
