import { useEffect, useState, useRef, Fragment } from "react";
// import axios from 'axios';
import { Link, useLocation, useNavigate } from "react-router-dom";

import { LogoSvg } from "../../utils/svg";
import ArrowImg from "../../assets/image svg/arrow-left.svg";
import LocateImg from "../../assets/image svg/Locatie.svg";
import CalendarImg from "../../assets/image svg/Calendar-N.svg";
import GenreIconImg from "../../assets/image svg/Music.svg";
import ShareImg from "../../assets/share-img.svg";
import GuestListSinImgs from "../../assets/image svg/Guestlist-sin1.svg";
import GuestListSinImg from "../../assets/image svg/Guestlist-sin.svg";
import CommunityTicketImg from "../../assets/community-ticket.svg";
import UserSinImg from "../../assets/image svg/User-sin.svg";
import WarningImg from "../../assets/image svg/Warning.svg";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import "dayjs/locale/nl";
import { NavLink } from "react-router-dom";
import Logo from "../../components/Logo";

import "./SingleEvent.css";
import { useTranslation } from "react-i18next";
import { CustomSelect } from "../home/Home";
import Icon from "../../components/Icon";

import { supabase } from "../../supabaseClient";
import { useSelector } from "react-redux";
import { selectUser } from "../../slices/userReducer";
import EventSignupForm from "../../components/EventSignupForm";
import EventLoginForm from "../../components/EventLoginForm";
import { getPublicImageUrl } from "../../helpers/helpers";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon as LeafletIcon } from "leaflet";
import EventMemberInfo from "../../components/EventMemberInfo";
import CouponModal from "../../components/CouponModal";

const defaultIcon = new LeafletIcon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

dayjs.extend(utc);

const SingleEvent = ({ token, setLanguage, language }) => {
  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const textRef = useRef(null);

  const [event, setEvent] = useState();

  const [soldOutStatus, setSoldOutStatus] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [eFormData, setEFormData] = useState({
    Firstname: "",
    Lastname: "",
    Email: "",
    dob: "",
    gender: "",
    event: "",
    event_id: "",
    token: token,
  });
  const [eventLink, setEventLink] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);

  const user = useSelector(selectUser);
  const [showCouponModal, setShowCouponModal] = useState(false);

  useEffect(() => {
    const getEvent = async () => {
      const searchParams = new URLSearchParams(location.search);
      const eventIdParam = searchParams.get("eventId");
      if (eventIdParam) {
        const event = await supabase
          .from("Events")
          .select("*, venue:Venue(*)")
          .eq("id", Number(eventIdParam))
          .single();

        setShowCouponModal(event.data.coupon_code !== "");
        setEvent(event.data);
      } else {
        navigate("/");
      }
    };

    getEvent();
  }, [navigate]);

  useEffect(() => {
    // If the user is logged in, set the form data for them.
    if (user.id) {
      setEFormData({
        ...eFormData,
        Firstname: user.first_name,
        Lastname: user.last_name,
        Email: user.email,
        dob: user.date_of_birth,
        gender: user.gender,
        token: user.token,
      });
    }
  }, [user]);

  useEffect(() => {
    if (event) {
      const ticketLink = event.ticket_link || "";
      if (event.sold_out) {
        setSoldOutStatus(true);
      }
      if (ticketLink !== "") {
        if (ticketLink.includes("http://localhost/")) {
          const modifiedLink = ticketLink.replace("http://localhost/", "");
          setEventLink(modifiedLink);
        } else {
          setEventLink(ticketLink);
        }
      }
      setEFormData({
        ...eFormData,
        event: event.name,
        event_id: event.id,
      });
    }
  }, [event]);

  const handleCopyClick = () => {
    if (textRef.current) {
      const textToCopy = textRef.current.innerText;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          textRef.current.innerText = "Copied!";
          textRef.current.style.color = "green";
        })
        .catch((error) => {
          console.error("Error copying text:", error);
        });
    }
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(`/`);
  };

  const getThanks = () => {
    navigate(`/thanks?eventId=${event.id}`, {
      state: event.id,
    });
  };

  const [eventSignups, setEventSignups] = useState(0);

  useEffect(() => {
    if (event) {
      supabase
        .from("EventSignUp")
        .select("*")
        .eq("event_id", event.id)
        .then((res) => {
          setEventSignups((res.data.length + Math.ceil(event.id % 10)) * 5);
        });
    }
  }, [event]);

  const validateName = (name) => {
    return /^[A-Za-z]+(\s[A-Za-z]+)*$/.test(name);
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!validateName(eFormData.Firstname)) {
      errors.Firstname = true;
      isValid = false;
    }

    if (!validateName(eFormData.Lastname)) {
      errors.Lastname = true;
      isValid = false;
    }

    if (!validateEmail(eFormData.Email)) {
      errors.Email = true;
      isValid = false;
    }

    if (eFormData.dob.length < 10) {
      errors.dob = true;
      isValid = false;
    }

    if (!["Man", "Vrouw", "Onzijdig"].includes(eFormData.gender)) {
      errors.gender = true;
      isValid = false;
    }

    setFormErrors(errors);

    // Check if any of the errors are true
    const hasErrors = Object.values(errors).some((error) => error);
    if (hasErrors) {
      if (refForm.current) {
        console.log("refForm", refForm.current);
        refForm.current?.scrollIntoView({ behavior: "smooth" });
      }
    }

    return isValid;
  };

  const refForm = useRef(null);

  const handleSubmit = async (e, isScroll = false) => {
    setBtnDisabled(true);
    e.preventDefault();

    if (isScroll) {
      refForm.current?.scrollIntoView({ behavior: "smooth" });
    }

    if (validateForm()) {
      // console.log('eFormData:', eFormData);

      const { data, error } = await supabase.from("EventSignUp").insert({
        event_id: event.id,
        first_name: eFormData.Firstname,
        last_name: eFormData.Lastname,
        date_of_birth: eFormData.dob,
        gender: eFormData.gender,
        email: eFormData.Email,
        user_id: user?.id || null,
      });

      console.log("DATA", data);
      console.log("ERROR", error);

      if (error) {
        console.error("Error inserting event signup:", error);
        setBtnDisabled(false);
        return;
      }

      setBtnDisabled(false);
      // console.log('response status', response.status)
      const { data: emailData, error: emailError } = await supabase.functions.invoke("sendEmail", {
        body: JSON.stringify({
          email: eFormData.Email,
          firstName: eFormData.Firstname,
          eventName: event.name,
          eventDate: dayjs(event.datetime).locale("nl").format("D MMMM"),
          eventVenue: event.venue.name,
          ticketUrl: eventLink,
          communityTicket: eventCategory === "Community ticket" ? true : false,
        }),
      });

      console.log("EMAIL DATA", emailData);
      console.log("EMAIL ERROR", emailError);

      if (eventCategory === "Community ticket") {
        var newTab = window.open(eventLink, "_blank");
        if (newTab) {
          newTab.opener = null; // Set opener to null for security
          newTab.location.href = eventLink; // Set the location explicitly
        } else {
          // Fallback: Open link in the current window if the new tab couldn't be opened
          window.location.href = eventLink;
        }
      }
      getThanks();
    }
  };

  if (!event) {
    return (
      <div
        className="hero-section-single event-page"
        style={{ minHeight: "100dvh" }}
      >
        <div className="container-single">
          <div className="row">
            <div className="arrow">
              <a href="/" onClick={handleBackClick}>
                <img src={ArrowImg} alt="arrow" className="logo" />
              </a>
            </div>
            <div className="logo-Img">
              <NavLink to="/">
                <LogoSvg />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const eventImage = event.event_image_filename;
  const eventTitle = event.name;

  const eventCity = event.city;
  const eventDate = dayjs(event.datetime).locale("nl").format("D MMM");
  const eventTime = dayjs(event.datetime).locale("nl").format("HH:mm");
  const eventCategory = event.category;
  const eventAge = event.minimum_age;
  const eventGenres = event.genres
    .split(", ")
    .map((genre) => {
      return genre === "Hitjes" ? t("hits") : genre;
    })
    .join(", ");
  const eventOriginalPrice = event.original_price;
  const eventOurPrice = event.our_price;
  const eventPriceLabel = `${t("instead_of")} ` || ""; // event.price_label
  const coupon = event.coupon_code;
  // setCouponCode(coupon);

  const description =
    eventCategory !== "Community ticket"
      ? t("guestlist_paragraph")
      : t("community_ticket_paragraph");

  const priceText =
    eventCategory !== "Community ticket"
      ? `${eventPriceLabel} ${event.currency}${eventOriginalPrice}`
      : `${eventPriceLabel} ${event.currency}${eventOriginalPrice}`;
  const eventPriceClass =
    soldOutStatus === true
      ? "soldout row justify-content-between"
      : "row justify-content-between";

  const shareContent = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: eventTitle,
          text: `Check out ${eventTitle} on 22night!`,
          url: `${window.location.href}&token=AMSLIJST`,
        });
        console.log("Content shared successfully!");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      console.error("Web Share API not supported");
    }
  };

  return (
    <div className="relative bg-[#12041f] min-h-screen lg:pb-[100px]">
      <CouponModal
        show={showCouponModal}
        setShow={setShowCouponModal}
        couponCode={coupon}
      />
      <div className="absolute hero-section w-full h-[600px] !z-[0]"></div>
      <div className="lg:pt-24 hero-section-single event-page">
        <div className="lg:hidden w-full flex items-center justify-center pt-12 relative z-[1000000]">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="/">
              <Icon
                icon="heroicons:arrow-left"
                className="w-12 h-12 text-white"
                alt="Whatsapp logo"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="max-lg:hidden absolute top-[50px] left-[50px] z-[1000000]">
          <Link to="/">
            <Icon
              icon="heroicons:arrow-left"
              className="w-12 h-12 text-white"
              alt="Whatsapp logo"
            />
          </Link>
        </div>
        <div className="max-lg:!mt-[24px] custom-container">
          <div className="absolute left-0 top-0 w-full h-[300px] max-lg:h-[460px] sm:h-[550px] flex justify-center !-pb-6 max-lg:!border-b !border-[#302640]">
            <div className="bg-black w-full h-full border-gray-900 border-b-2 overflow-hidden">
              <img
                src={getPublicImageUrl(eventImage)}
                alt={"Event image"}
                className="w-full h-full object-cover blur-2xl"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-white/0 via-black/5 to-black/20"></div>
            </div>
          </div>
          <div className="event-img text-center relative max-lg:h-[350px]">
            <div className="w-full flex justify-center lg:items-center lg:h-[430px]">
              <div className="relative w-[500px] max-w-[95vw] max-h-[430px] bg-[#130826] max-lg:p-[16px] lg:p-[24px] rounded-[12px]">
                <img
                  src={getPublicImageUrl(eventImage)}
                  alt={"Event image"}
                  className="!w-full !h-auto !rounded-[8px]"
                />
                <div className="absolute max-lg:top-[25px] max-lg:right-[-10px] lg:top-[40px] lg:right-[40px]">
                  <li
                    className="event date cursor-pointer !bg-transparent"
                    onClick={shareContent}
                  >
                    <a className="shadow-md border-2 border-white !p-3 bg-neutral-200 text-white flex items-start border-2 gap-2 border-white rounded-full">
                      {/* <span className="text-color">Share</span> */}
                      <Icon icon="mi:share" className="h-8 w-8 text-black" />
                    </a>
                  </li>
                </div>
                <h1 className="line-clamp-2 text-start w-full text-[26px] text-white font-bold !p-0 !mt-[15px] !leading-[32px]">
                  {eventTitle}
                </h1>
                <div className="event-information-box">
                  <ul className="row !pt-4">
                    <li className="event date">
                      <a className="text-white flex items-start">
                        <img src={LocateImg} alt="Locatie" />
                        <span className="text-color ml-1 mt-1">
                          {eventCity}
                        </span>
                      </a>
                    </li>

                    <li className="event date">
                      <a className="text-white flex items-start">
                        <img src={CalendarImg} alt="Calendar" />
                        <span className="text-color ml-1 mt-1">
                          {eventDate} {eventTime}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="lg:hidden"> */}
            {/* <img src={getPublicImageUrl(eventImage)} alt={"Event image"} /> */}
            {/* </div> */}
          </div>
          <div className="xl:flex justify-between gap-3">
            <div className="relative lg:max-w-[50%] 2xl:max-w-[55%] min-[480px]:mt-[50px]">
              {/* The event is not exclusive or if the event is exclusive show if the user exists */}
              {event.exclusive_event && !user.id && (
                <div className="lg:hidden z-0 relative !pt-[24px]">
                  <EventLoginForm redirectUrl={window.location.href} />
                </div>
              )}
              {/*If its  exclusive event and the user is not signed in, then show them the login form */}
              {!event.exclusive_event && !user.id && (
                <div ref={refForm} className="z-0 relative w-full lg:hidden !pt-[24px]">
                  <EventSignupForm
                    eFormData={eFormData}
                    formErrors={formErrors}
                    setEFormData={setEFormData}
                    setFormErrors={setFormErrors}
                    event={event}
                    setBtnDisabled={setBtnDisabled}
                    soldOutStatus={soldOutStatus}
                    handleSubmit={handleSubmit}
                    btnDisabled={btnDisabled}
                    showMobile={true}
                  />
                </div>
              )}

              {!user.id && (
                <div className="!my-[24px] lg:hidden flex items-center justify-center">
                  <hr className="w-full mx-[15px] !border-[#302640]" />
                </div>
              )}

              <div
                className={`${
                  user.id ? "mt-[48px]" : ""
                } lg:mt-[48px] lg:!border-t-0 mr-6 border-[#302640] px-[15px]`}
              >
                {coupon && (
                  <div className="multipan-event-box vriendenticket">
                    <div className="events-image">
                      <img src={GuestListSinImgs} alt="guest-list-sin" />
                    </div>
                    <div className="events-content">
                      <h5 className="text-white">
                        {t("receive_code_after_enter")}
                      </h5>
                      <p className="text-light-gray event-code">
                        <span className="event-code__span">
                          {/*<img className='copy-icon' src={copy} alt="" />*/}
                          <span ref={textRef} onClick={handleCopyClick}>
                            {coupon}
                          </span>
                        </span>
                      </p>
                    </div>
                  </div>
                )}
                <div className="multipan-event-box vriendenticket">
                  <div className="events-image">
                    {eventCategory === "Community ticket" ? (
                      <img src={CommunityTicketImg} alt="guest-list-sin" />
                    ) : (
                      <img src={GuestListSinImg} alt="guest-list-sin" />
                    )}
                  </div>
                  <div className="events-content">
                    <h5 className="text-white">{eventCategory}</h5>
                    <p className="text-light-gray !leading-[18px] !text-[14px]">
                      {description}
                    </p>
                  </div>
                </div>

                <div className="multipan-event-box">
                  <div className="events-image">
                    <img src={UserSinImg} alt="user-sin" />
                  </div>
                  <div className="events-content">
                    <h5 className="text-white">
                      {t("minimum_age")} {eventAge}+
                    </h5>
                  </div>
                </div>

                <div className="multipan-event-box">
                  <div className="events-image">
                    <img src={WarningImg} alt="warning" />
                  </div>
                  <div className="events-content">
                    <h5 className="text-white">
                      {t("refund_and_resell_not_possible")}
                    </h5>
                  </div>
                </div>
              </div>

              <div className="!my-[24px] flex items-center justify-center">
                <hr className="w-full mx-[15px] !border-[#302640]" />
              </div>

              <div className="flex w-full px-[15px] flex-col items-center">
                <h3 className="w-full !text-start text-[20px] font-semibold text-white">
                  {t("what_music_can_i_expect")}
                </h3>
                <div className="w-full items-center flex flex-col gap-3">
                  {eventGenres.split(", ").map((genre, index) => (
                    <p className="text-[18px] text-neutral-200 bg-[#F7F2fD10] rounded-[8px] py-[16px] px-[12px] w-full">
                      {genre}
                    </p>
                  ))}
                </div>
              </div>

              <div className="!my-[24px] flex items-center justify-center">
                <hr className="w-full mx-[15px] !border-[#302640]" />
              </div>

              <div className="flex w-full px-[15px] flex-col justify-center">
                <h3 className="w-full !text-start text-[20px] font-semibold text-white">
                  Info
                </h3>
                <p className="text-[14px] text-neutral-200" style={{ whiteSpace: 'pre-line' }}>
                  {event.description}
                </p>
              </div>

              <div className="!my-[24px] flex items-center justify-center">
                <hr className="w-full mx-[15px] !border-[#302640]" />
              </div>

              <div className="flex w-full px-[15px] flex-col justify-center">
                <h3 className="w-full !text-start text-[20px] font-semibold text-white">
                  {t("location")}
                </h3>
                <MapContainer
                  className="max-w-full max-h-[200px] !rounded-[8px] z-0"
                  center={[event.venue.long, event.venue.lat]}
                  zoom={11}
                  scrollWheelZoom={false}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  <Marker
                    icon={defaultIcon}
                    position={[event.venue.long, event.venue.lat]}
                  >
                    <Popup>{event.venue.name}</Popup>
                  </Marker>
                </MapContainer>
                <div className="mt-[16px]">
                  <h4 className="text-[16px] font-semibold text-white leading-[22px]">
                    {event.venue.name}
                  </h4>
                  <p className="text-neutral-200 text-[14px] mt-0">
                    {event.venue.street_address}, {event.venue.zip_code}{" "}
                    {event.venue.city}
                  </p>
                </div>
              </div>
            </div>
            {/*If its  exclusive event and the user is not signed in, then show them the login form */}
            {!event.exclusive_event && !user.id && (
              <EventSignupForm
                eFormData={eFormData}
                formErrors={formErrors}
                setEFormData={setEFormData}
                setFormErrors={setFormErrors}
                event={event}
                setBtnDisabled={setBtnDisabled}
                soldOutStatus={soldOutStatus}
                handleSubmit={handleSubmit}
                btnDisabled={btnDisabled}
                showMobile={false}
              />
            )}
            {event.exclusive_event && !user.id && (
              <div className="z-0 relative w-full max-lg:hidden">
                <EventLoginForm redirectUrl={window.location.href} />
              </div>
            )}
            {user.id && (
              <div className="lg:mt-[50px] max-lg:hidden 2xl:!w-[35%] lg:w-[50%] h-fit flex items-center flex-col relative z-0">
                <EventMemberInfo
                  eventSignups={eventSignups}
                  event={event}
                  handleSubmit={handleSubmit}
                  soldOutStatus={soldOutStatus}
                  btnDisabled={btnDisabled}
                />
              </div>
            )}
          </div>
        </div>
        {(user.id || !event.exclusive_event) && (
          // <div className="event-price-box mobile">
          //   <div
          //     className={`${eventPriceClass} flex items-center justify-between`}
          //   >
          //     <div className="price-box text-white">
          //       <h3>
          //         {soldOutStatus === true
          //           ? "This event is sold out"
          //           : `€${eventOurPrice}`}
          //       </h3>
          //       <h6>{soldOutStatus === true ? "" : priceText}</h6>
          //     </div>

          //     <div className="price-button">
          //       <button
          //         className="button openPopupButtons !text-[17px] !py-[24px] !px-[30px] premium-button"
          //         type="button"
          //         onClick={handleSubmit}
          //         disabled={
          //           soldOutStatus === true || btnDisabled === true
          //             ? "disabled"
          //             : ""
          //         }
          //       >
          //         Tickets
          //       </button>
          //     </div>
          //   </div>
          // </div>
          <div className="border-t border-gray-700 event-price-box !h-[160px] mobile w-full">
            <div className="flex justify-between w-full items-center">
              <h3 className="!pb-0 text-[18px] font-semibold text-white">
                Totaal
              </h3>
              <h3 className="!pb-0 text-[22px] font-semibold text-white">
                {event.currency}{event.our_price.toFixed(2)}
              </h3>
            </div>
            <div className="flex justify-between w-full items-center">
              <h3 className="text-[16px] font-semibold text-neutral-300">
                Originele ticket prijs
              </h3>
              <h3 className="text-[16px] line-through font-semibold text-neutral-300">
                {event.currency}{event.original_price.toFixed(2)}
              </h3>
            </div>
            <div className="w-full">
              <button
                className="mt-[4px] !w-full button openPopupButtons !text-[17px] !py-[24px] !px-[30px] premium-button"
                type="button"
                onClick={handleSubmit}
                disabled={
                  soldOutStatus === true || btnDisabled === true
                    ? "disabled"
                    : ""
                }
              >
                {event.category === "Community ticket"
                  ? "Claim ticket"
                  : "Claim Guestlist Spot"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleEvent;
