import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import Icon from "../components/Icon";
import { supabase } from "../supabaseClient";
import EditVenueModal from "./EditVenueModal";
import EditLifestyleCategoryModal from "../components/EditLifestyleCategoryModal";

export default function ManageLifestyleCategories() {
  const [categories, setCategories] = useState([]);

  const [currentCategory, setCurrentCategory] = useState();

  const [showEditCategoryModal, setShowEditCategoryModal] = useState({
    show: false,
    create: true,
  });

  useEffect(() => {
    supabase
      .from("LifestyleCategory")
      .select("*")
      .then((res) => {
        setCategories(res.data);
      });
  }, []);

  const removeCategory = (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      supabase
        .from("LifestyleCategory")
        .delete()
        .eq("id", id)
        .then((res) => {
          setCategories(categories.filter((category) => category.id !== id));
        });
    }
  };

  return (
    <div className="w-full text-white text-3xl mt-12 overflow-x-auto p-5">
      <EditLifestyleCategoryModal
        show={showEditCategoryModal.show}
        setShow={(show) => {
          setShowEditCategoryModal({ ...showEditCategoryModal, show: show });
        }}
        create={showEditCategoryModal.create}
        currentCategory={currentCategory}
        setCurrentCategory={setCurrentCategory}
      />

      <div className="flex justify-between items-center w-full">
        <h1 className="text-white !text-5xl font-semibold !text-start w-fit">
          Manage lifestyle categories
        </h1>
        <button
          onClick={() => {
            setShowEditCategoryModal({
              show: true,
              create: true,
            });
          }}
          className="bg-white py-5 px-12 rounded-lg shadow-md font-semibold text-black !text-[20px] flex items-center justify-center gap-2"
        >
          <Icon icon="heroicons:plus" className="h-9 w-9" />
        </button>
      </div>
      <Table className="mt-8 mb-[50px]">
        <Table.Head>
          <Table.HeadCell className="text-3xl capitalize">Name</Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize">Icon</Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize">Display Order</Table.HeadCell>
          <Table.HeadCell className="text-3xl capitalize">
            <span className="sr-only">Actions</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {categories.map((category) => {
            return (
              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                <Table.Cell className="p-12 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {category.name}
                </Table.Cell>
                <Table.Cell>
                  <Icon icon={category.icon} className="h-9 w-9" />
                </Table.Cell>
                <Table.Cell className="p-12 text-2xl whitespace-nowrap font-medium text-gray-900 dark:text-white">
                  {category.index || 0}
                </Table.Cell>
                <Table.Cell>
                  <button
                    onClick={() => {
                      removeCategory(category.id);
                    }}
                    className="font-medium text-red-600 hover:underline dark:text-red-500 text-2xl"
                  >
                    <Icon icon="heroicons:trash" className="h-9 w-9" />
                  </button>
                  <button
                    onClick={() => {
                      setCurrentCategory(category);
                      setShowEditCategoryModal({
                        show: true,
                        create: false,
                      });
                    }}
                    className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 text-2xl"
                  >
                    <Icon icon="heroicons:pencil-square" className="h-9 w-9" />
                  </button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}
