import React, { Suspense, useEffect, useState } from "react";
import useWidth from "../hooks/useWidth";
import useSidebar from "../hooks/useSidebar";
import useMenuLayout from "../hooks/useMenuLayout";
import useMenuHidden from "../hooks/useMenuHidden";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import { useLocation, useNavigate } from "react-router-dom";
import AdminSidebar from "../components/AdminSidebar";
import { adminUserIds } from "../helpers/helpers";
import MobileMenu from "../components/MobileMenu";
import AdminMobileMenu from "../components/AdminMobileMenu";

export default function AdminLayout({ children }) {
  const { width, breakpoints } = useWidth();
  const [collapsed] = useSidebar();

  const switchHeaderClass = () => {
    if (menuType === "horizontal" || menuHidden) {
      return "ml-0";
    } else if (collapsed) {
      return "ml-[72px]";
    } else {
      return "ml-[300px]";
    }
  };
  // content width
  const [menuType] = useMenuLayout();
  const [menuHidden] = useMenuHidden();

  const navigate = useNavigate();

  const user = useSelector(selectUser);

  const location = useLocation();


  if (!adminUserIds.includes(user.id)) {
    setTimeout(() => {
      navigate("/");
    }, 0);
  }

  // mobile menu
  return (
    <>
      {/* TODO: add mobile header with back,logo and language  */}
      {menuType === "vertical" && width > breakpoints.xl && !menuHidden && (
        <AdminSidebar />
      )}
      {width < breakpoints.lg && <AdminMobileMenu path={location.pathname} />}
      <div
        className={`content-wrapper transition-all duration-150 ${
          width > 1280 ? switchHeaderClass() : ""
        }`}
      >
        {/* md:min-h-screen will h-full*/}
        <div className="page-content page-min-height">
          <div className={"w-full"}>
            {/* TODO: ADD LOADING ANIMATION */}
            <Suspense fallback={<></>}>
              <motion.div
                key={window.location.pathname}
                initial="pageInitial"
                animate="pageAnimate"
                exit="pageExit"
                variants={{
                  pageInitial: {
                    opacity: 0,
                    y: 50,
                  },
                  pageAnimate: {
                    opacity: 1,
                    y: 0,
                  },
                  pageExit: {
                    opacity: 0,
                    y: -50,
                  },
                }}
                transition={{
                  type: "tween",
                  ease: "easeInOut",
                  duration: 0.5,
                }}
                className=""
              >
                {children}
              </motion.div>
            </Suspense>
          </div>
        </div>
      </div>
      {/* {width < breakpoints.md && <MobileFooter />} */}
    </>
  );
}
