import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import Icon from "./Icon";
import { useTranslation } from "react-i18next";

export default function FAQSection() {
  const { t } = useTranslation();

  const items = [
    {
      question: t("faq_question_1"),
      answer: t("faq_answer_1"),
    },
    {
      question: t("faq_question_2"),
      answer: t("faq_answer_2"),
    },
    {
      question: t("faq_question_3"),
      answer: t("faq_answer_3"),
    },
    {
      question: t("faq_question_4"),
      answer: t("faq_answer_4"),
    },
    {
      question: t("faq_question_5"),
      answer: t("faq_answer_5"),
    },
    {
      question: t("faq_question_6"),
      answer: t("faq_answer_6"),
    },
    {
      question: t("faq_question_7"),
      answer: t("faq_answer_7"),
    },
    {
      question: t("faq_question_8"),
      answer: t("faq_answer_8"),
    },
  ];

  return (
    <>
      <h1 className="max-lg:!mt-[-10vh] lg:!mt-[-10vh] 2xl:!mt-[-15vh] !text-[28px] font-semibold text-white text-center w-full">
        {t("faq_title")}
      </h1>
      <div className="mt-8 w-full divide-y divide-white/5 rounded-xl bg-white/5">
        {items.map((item, index) => (
          <Disclosure as="div" className="p-10" defaultOpen={index === 0}>
            <DisclosureButton className="group flex w-full items-center justify-between">
              <span className="max-w-[85%] text-start text-[16px] lg:text-[20px] font-medium text-white group-data-[hover]:text-white/80">
                {item.question}
              </span>
              <Icon
                icon="heroicons:chevron-down"
                className="h-10 w-10 text-white group-data-[hover]:text-white/50 group-data-[open]:rotate-180"
              />
            </DisclosureButton>
            <DisclosurePanel className="text-[14px] lg:text-[18px] text-white/50">
              {item.answer}
            </DisclosurePanel>
          </Disclosure>
        ))}
      </div>
    </>
  );
}
