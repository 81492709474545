import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { v4 as uuidv4 } from "uuid";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Button,
} from "@chakra-ui/react";
import { Icon } from "@chakra-ui/react";

export default function EditDealModal({ show, setShow, create, currentDeal }) {
  const [categories, setCategories] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    supabase
      .from("LifestyleCategory")
      .select("*")
      .then((res) => {
        setCategories(res.data);
      });

    // Fetch cities from the City table
    supabase
      .from("City")
      .select("*")
      .then((res) => {
        setCities(res.data.map((city) => city.name));
      });

    // If editing existing deal, split cities string into array
    if (!create && currentDeal?.cities) {
      setSelectedCities(
        currentDeal.cities.split(",").map((city) => city.trim())
      );
    }
  }, [currentDeal, create]);

  const [formData, setFormData] = useState({
    name: "",
    category_id: 0,
    ticket_link: "",
    sold_out: false,
    dealOfTheWeek: false,
    image_filename: "",
    original_price: "0",
    our_price: "0",
    teaser: "",
    index: 0,
    advantage_1_title: "",
    advantage_1_description: "",
    advantage_2_title: "",
    advantage_2_description: "",
    advantage_3_title: "",
    advantage_3_description: "",
    currency: "€",
  });

  const [error, setError] = useState({
    name: false,
    category: false,
    ticket_link: false,
    sold_out: false,
    dealOfTheWeek: false,
    image_filename: false,
    original_price: false,
    our_price: false,
    teaser: false,
    index: false,
  });

  useEffect(() => {
    if (!create && currentDeal) {
      setFormData({ ...currentDeal });
    }
  }, [currentDeal, create]);

  useEffect(() => {
    const clearError = (field) => {
      if (error[field]) {
        setError((prev) => ({ ...prev, [field]: false }));
      }
    };

    Object.keys(formData).forEach((field) => {
      clearError(field);
    });
  }, [formData]);

  const saveDeal = () => {
    if (
      Object.values(formData).some((item, index) => {
        if (
          item === "" &&
          ![
            "advantage_1_title",
            "advantage_2_title",
            "advantage_3_title",
            "advantage_1_description",
            "advantage_2_description",
            "advantage_3_description",
          ].includes(Object.keys(formData)[index])
        ) {
          setError({ ...error, [Object.keys(formData)[index]]: true });
          return true;
        }
      })
    ) {
      return;
    }

    if (create === true) {
      supabase
        .from("Deals")
        .insert({
          ...formData,
          original_price: formData.original_price.replace(",", "."),
          our_price: formData.our_price.replace(",", "."),
        })
        .then((res) => {
          if (res.error) {
            setError(true);
          } else {
            setShow(false);
          }
        })
        .catch((res) => {
          setError(true);
        });
    } else {
      supabase
        .from("Deals")
        .update({ ...formData })
        .eq("id", currentDeal.id)
        .then((response) => {
          if (response.error) {
            setError(true);
          } else {
            setShow(false);
          }
        });
    }
  };

  const uploadImage = (e) => {
    const uuid = uuidv4();
    const file = e.target.files[0];

    const asset_name = `${uuid}-${file.name}`;

    const { data, error } = supabase.storage
      .from("images")
      .upload(asset_name, file, { upsert: true });

    setFormData({ ...formData, image_filename: asset_name });
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto "
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full h-screen bg-[#00000040] fixed z-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-12 w-12" aria-hidden="true" />
                </button>
              </div>
              <div className="mb-3 flex justify-center w-full">
                <div className="flex-col flex items-center w-full">
                  <div className="text-center w-full">
                    <h1 className="text-5xl font-bold text-black w-full">
                      {create ? "Create a new deal" : "Edit deal"}
                    </h1>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Deal name</label>
                <input
                  placeholder="Deal name"
                  value={formData.name}
                  type="text"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.name ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-neutral-500 mb-0">
                  Category
                </label>
                <select
                  className={`dark-input ${
                    error.category ? "border-red-500" : "border-gray-300"
                  }`}
                  value={
                    categories.find(
                      (category) => category.id === formData.category_id
                    )?.id
                  }
                  onChange={(e) => {
                    setFormData({ ...formData, category_id: e.target.value });
                  }}
                >
                  <option value="">Choose deal category</option>
                  {categories.map((category) => {
                    return <option value={category.id}>{category.name}</option>;
                  })}
                </select>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Ticket link</label>
                <input
                  placeholder="Ticket link"
                  value={formData.ticket_link}
                  type="text"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.ticket_link ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, ticket_link: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Deal of the week
                </label>
                <input
                  checked={formData.dealOfTheWeek}
                  type="checkbox"
                  className={`text-2xl w-8 h-8 text-black bg-white border-2 ${
                    error.dealOfTheWeek ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      dealOfTheWeek: e.target.checked,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Sold out</label>
                <input
                  checked={formData.sold_out}
                  type="checkbox"
                  className={`text-2xl w-8 h-8 text-black bg-white border-2 ${
                    error.sold_out ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, sold_out: e.target.checked });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Original price
                </label>
                <input
                  value={formData.original_price}
                  type="text"
                  placeholder="50"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.original_price ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      original_price: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Our price</label>
                <input
                  value={formData.our_price}
                  type="text"
                  placeholder="22,50"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.our_price ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, our_price: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-neutral-500 mb-0">
                  Currency
                </label>
                <select
                  className={`dark-input`}
                  value={formData.currency}
                  onChange={(e) => {
                    setFormData({ ...formData, currency: e.target.value });
                  }}
                >
                  <option value="€">€</option>
                  <option value="Rp">Rp</option>
                </select>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Teaser</label>
                <textarea
                  value={formData.teaser}
                  placeholder="Teaser"
                  rows="1"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.teaser ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    setFormData({ ...formData, teaser: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-neutral-500 mb-0">
                  Display order (lower numbers appear first)
                </label>
                <input
                  placeholder="0"
                  value={formData.index}
                  type="text"
                  className={`dark-input ${
                    error.index ? "border-red-500" : "border-gray-300"
                  }`}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      index: parseInt(e.target.value) || 0,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Advantage 1 title
                </label>
                <textarea
                  value={formData.advantage_1_title}
                  placeholder="Advantage"
                  rows="1"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advantage_1_title: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Advantage 1 description
                </label>
                <textarea
                  value={formData.advantage_1_description}
                  placeholder="Advantage"
                  rows="3"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advantage_1_description: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Advantage 2 title
                </label>
                <textarea
                  value={formData.advantage_2_title}
                  placeholder="Advantage"
                  rows="1"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advantage_2_title: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Advantage 2 description
                </label>
                <textarea
                  value={formData.advantage_2_description}
                  placeholder="Advantage"
                  rows="3"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advantage_2_description: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Advantage 3 title
                </label>
                <textarea
                  value={formData.advantage_3_title}
                  placeholder="Advantage"
                  rows="1"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advantage_3_title: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Advantage 3 description
                </label>
                <textarea
                  value={formData.advantage_3_description}
                  placeholder="Advantage"
                  rows="3"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advantage_3_description: e.target.value,
                    });
                  }}
                />
              </div>

              {/* Event image */}
              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Deal image</label>
                <input
                  type="file"
                  className={`text-2xl text-black bg-white border-2 ${
                    error.image_filename ? "border-red-500" : "border-gray-300"
                  } p-4 rounded-lg`}
                  onChange={(e) => {
                    uploadImage(e);
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-neutral-500 mb-0">Cities</label>
                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    className="btn btn-dropdown dropdown-toggle text-left text-2xl text-black bg-white border-2 p-4 rounded-lg"
                    rightIcon={
                      <Icon icon="heroicons:chevron-down" className="w-6 h-6" />
                    }
                  >
                    <span className="selectedOption">
                      {selectedCities.length > 0
                        ? `${selectedCities.length} cities selected`
                        : "Select cities"}
                    </span>
                  </MenuButton>
                  <MenuList
                    className="menu-list menu-checkbox-list"
                    maxH="300px"
                    overflowY="auto"
                  >
                    <MenuOptionGroup
                      type="checkbox"
                      value={selectedCities}
                      onChange={(values) => {
                        setSelectedCities(values);
                        setFormData({
                          ...formData,
                          cities: values.join(", "),
                        });
                      }}
                    >
                      {cities.map((city) => (
                        <MenuItemOption key={city} value={city}>
                          {city}
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </div>

              {Object.values(error).some((item) => item) && (
                <p className="text-3xl font-semibold mt-5 text-red-500">
                  Not all fields are valid. Invalid fields:{" "}
                  {Object.keys(error)
                    .filter((key) => error[key])
                    .map(
                      (key) =>
                        key.replace(/_/g, " ").charAt(0).toUpperCase() +
                        key.replace(/_/g, " ").slice(1)
                    )
                    .join(", ")}
                </p>
              )}

              <div className="flex items-center justify-end w-full pt-9">
                <button
                  onClick={() => {
                    saveDeal();
                  }}
                  className="text-white transition-all px-6 lg:px-12 py-3 rounded-lg text-3xl font-semibold hover:bg-[#38c28f] bg-[#07af71]"
                >
                  Save deal
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
