import Logo from "./Logo";
import { Link } from "react-router-dom";
import DjSet from "../assets/dj-set.svg";
import FeedbackIcon from "../assets/feedback-icon.svg";
import ShoppingBag from "../assets/shopping-bag.svg";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userReducer";
import Icon from "./Icon";
import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";

const AdminSidebar = () => {
  const currentSlug = window.location.href.split("/").pop();
  const user = useSelector(selectUser);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showEventMenu, setShowEventMenu] = useState(false);
  const [showLifestyleMenu, setShowLifestyleMenu] = useState(false);

  useEffect(() => {
    supabase
      .from("City")
      .select("*")
      .then((res) => {
        setCities(res.data || []);
      });

    supabase
      .from("LifestyleCategory")
      .select("*")
      .order("index", { ascending: true })
      .then((res) => {
        setCategories(res.data || []);
      });
  }, []);

  return (
    <div>
      <div
        className="fixed top-0 z-[999] h-screen bg-[#00000040] py-[64px] px-[24px] text-white lg:w-[300px] w-[0px] shadow-lg"
        style={{
          transition: "width 0.2s cubic-bezier(0.39, 0.575, 0.565, 1)",
        }}
      >
        <Link to="/" className="">
          <div className="ml-6">
            <Logo />
          </div>
        </Link>

        <div className="sidebar-menu h-auto mt-[32px]">
          <button
            onClick={() => setShowEventMenu(!showEventMenu)}
            className={`mb-[16px] p-[12px] w-full flex items-center justify-between gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug.startsWith("events") ? "bg-[#D0ADFB20]" : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <div className="flex items-center gap-[12px]">
              <img src={DjSet} className="h-[20px] w-[20px]" alt="DJ Set" />
              <p className="text-[16px]">Events</p>
            </div>
            <Icon
              icon={showEventMenu ? "heroicons:chevron-up" : "heroicons:chevron-down"}
              className="h-[20px] w-[20px]"
            />
          </button>

          <div
            className="overflow-hidden transition-all duration-300 pl-[32px]"
            style={{
              maxHeight: showEventMenu ? "300px" : "0px",
              opacity: showEventMenu ? 1 : 0
            }}
          >
            <div className="max-h-[300px] overflow-y-auto pr-2 custom-scrollbar">
              <Link
                to="/admin/events"
                className={`mb-[8px] p-[12px] flex items-center gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB20] hover:shadow-lg ${
                  currentSlug === "events" ? "bg-[#D0ADFB20]" : ""
                }`}
              >
                <p className="text-[14px]">All Events</p>
              </Link>
              {cities.map((city) => (
                <Link
                  key={city.id}
                  to={`/admin/events/${city.name}`}
                  className={`mb-[8px] p-[12px] flex items-center gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB20] hover:shadow-lg ${
                    currentSlug === city.name ? "bg-[#D0ADFB20]" : ""
                  }`}
                >
                  <p className="text-[14px]">{city.name}</p>
                </Link>
              ))}
            </div>
          </div>

          <button
            onClick={() => setShowLifestyleMenu(!showLifestyleMenu)}
            className={`mb-[16px] p-[12px] w-full flex items-center justify-between gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug.startsWith("lifestyle") ? "bg-[#D0ADFB20]" : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <div className="flex items-center gap-[12px]">
              <img src={ShoppingBag} className="h-[20px] w-[20px]" alt="Shopping bag" />
              <p className="text-[16px]">Lifestyle deals</p>
            </div>
            <Icon
              icon={showLifestyleMenu ? "heroicons:chevron-up" : "heroicons:chevron-down"}
              className="h-[20px] w-[20px]"
            />
          </button>

          <div
            className="overflow-hidden transition-all duration-300 pl-[32px]"
            style={{
              maxHeight: showLifestyleMenu ? "300px" : "0px",
              opacity: showLifestyleMenu ? 1 : 0
            }}
          >
            <div className="max-h-[300px] overflow-y-auto pr-2 custom-scrollbar">
              <Link
                to="/admin/lifestyle"
                className={`mb-[8px] p-[12px] flex items-center gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB20] hover:shadow-lg ${
                  currentSlug === "lifestyle" ? "bg-[#D0ADFB20]" : ""
                }`}
              >
                <p className="text-[14px]">All Deals</p>
              </Link>
              {categories.map((category) => (
                <Link
                  key={category.id}
                  to={`/admin/lifestyle/${category.name}`}
                  className={`mb-[8px] p-[12px] flex items-center gap-[12px] rounded-lg font-normal !text-white hover:bg-[#D0ADFB20] hover:shadow-lg ${
                    currentSlug === category.name ? "bg-[#D0ADFB20]" : ""
                  }`}
                >
                  <p className="text-[14px]">{category.name}</p>
                </Link>
              ))}
            </div>
          </div>

          <Link
            to="/admin/venues"
            className={`mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug === "venues"
                ? "bg-[#D0ADFB20]"
                : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <img
              src={FeedbackIcon}
              className="h-[20px] w-[20px]"
              alt="Feedback icon"
            />
            <p className="text-[16px]">Venues</p>
          </Link>
          <Link
            to="/admin/cities"
            className={`mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug === "cities"
                ? "bg-[#D0ADFB20]"
                : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <Icon icon="heroicons:map-pin" className="h-[20px] w-[20px]" />
            <p className="text-[16px]">Cities</p>
          </Link>
          <Link
            to="/admin/suggestions"
            className={`mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug === "suggestions"
                ? "bg-[#D0ADFB20]"
                : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <Icon icon="heroicons:inbox-arrow-down" className="h-[20px] w-[20px]" />
            <p className="text-[16px]">Suggestions</p>
          </Link>
          <Link
            to="/admin/lifestyle-categories"
            className={`mb-[16px] p-[12px] flex items-center justify-start gap-[12px] rounded-lg font-normal !text-white ${
              currentSlug === "lifestyle-categories"
                ? "bg-[#D0ADFB20]"
                : "hover:bg-[#D0ADFB20]"
            } hover:shadow-lg`}
          >
            <Icon icon="heroicons:tag" className="h-[20px] w-[20px]" />
            <p className="text-[16px]">Lifestyle categories</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
