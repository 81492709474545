import React from "react";
import GoogleLogo from "../assets/google-logo.png";

import { supabase } from "../supabaseClient";
import { useTranslation } from "react-i18next";
// import { baseUrl } from "../helpers/helpers";

export default function GoogleAuth({ redirectUrl }) {
  const googleLogin = () => {
    const baseUrl =
      process.env.NODE_ENV === "production"
        ? `https://22night.com`
        : "http://localhost:3000";

    console.log("REDIRECT URL", redirectUrl);

    supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo:
          `${baseUrl}/create-account?provider=google` +
          (redirectUrl ? `&redirectUrl=${redirectUrl}` : ""),
      },
    });
  };

  const { t } = useTranslation();

  return (
    <button
      type="button"
      onClick={() => {
        googleLogin();
      }}
      className="rounded-[8px] text-white text-[16px] font-semibold gap-3 flex items-center w-full bg-transparent justify-center p-[14px] border-2 border-[#B9B6BF]"
    >
      <img src={GoogleLogo} className="object-cover h-8 w-8" />
      {t("continue_with")} Google
    </button>
  );
}
