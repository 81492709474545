import { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { TrendingCheckSvg, TrendingStarSvg } from "../utils/svg";
import EmptySearchResult from "./EmptySearchResult";
import { useTranslation } from "react-i18next";
import SearchIconSvg from "../assets/search-icon.svg";
import { useNavigate } from "react-router-dom";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";

dayjs.extend(utc);
dayjs.extend(timezone);

const SearchItem = ({ searchItem, isEven, formattedCurrentDate }) => {
  const navigate = useNavigate();

  const handleItemClick = () => {
    navigate(`/deal?deal_id=${searchItem.id}`, {
      state: searchItem.id,
    });
    // }
    // return null;
  };

  const eventName = searchItem.name
    .split(/[\s-]+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  return (
    <a onClick={handleItemClick}>
      <div className="search-information-service serach-info">
        <div className="row">
          <div className="search-information-image">
            {isEven ? <TrendingCheckSvg /> : <TrendingStarSvg />}
          </div>

          <div className="search-information-content">
            <h5 className="truncate overflow-ellipsis">{eventName}</h5>
            <p>
              {searchItem.cities} &bull;{" "}
              {/* {searchItem.category.charAt(0).toUpperCase() +
                searchItem.category.slice(1).toLowerCase()} */}
            </p>
          </div>
        </div>
      </div>
    </a>
  );
};

const DealSearchBar = ({ deals, formattedCurrentDate }) => {
  const [searchForm, setSearchForm] = useState(false);
  const [smSearchForm, setSmSearchForm] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const searchMobile = useRef(null);

  const title =
    searchValue.length < 1 ? "Deals of the Week" : "Search results:";

  const trendingData =
    deals.length > 0
      ? deals.filter((deal) => deal.dealOfTheWeek === true)
      : [];
  const filteredData =
    deals.length > 0
      ? deals.filter((deal) =>
          deal.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      : [];

  const searchInformationBoxRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchInformationBoxRef.current &&
        !searchInformationBoxRef.current.contains(event.target) &&
        searchForm
      ) {
        setSearchForm(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [searchForm]);

  const handleSearchFormFocus = () => {
    // Додано затримку в 100 мілісекунд
    setTimeout(() => {
      setSearchForm(true);
      setSmSearchForm(true);
      searchMobile.current.focus();
    }, 100);
  };

  const { t } = useTranslation();

  return (
    <div className="mt-[-75px] z-[10000]">
      <form className="rounded-[50px] w-full lg:w-[92%] flex items-center gap-[12px] lg:mt-[31px] text-center m-auto">
        <div className="relative m-auto w-full lg:w-[60%]">
          <img
            src={SearchIconSvg}
            className="absolute z-10 lg:left-[20px] left-[15px] top-[20px] lg:top-[27px]"
            alt="Search icon"
          />
          <input
            type="text"
            className="mt-[5px] text-[14px] lg:text-[16px] text-[#80737A] opacity-90 py-[15px] pl-[45px] pr-[34px] lg:py-[21px] lg:pl-[52px] lg:pr-[42px] rounded-[50px] w-full"
            placeholder={t("search_deals_placeholder")}
            autoComplete="off"
            name="search"
            id="showModalBtnbottom"
            onFocus={handleSearchFormFocus}
            // onBlur={() => setSearchForm(false)}
            onClick={handleSearchFormFocus}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </form>
      <div
        ref={searchInformationBoxRef}
        className={clsx(
          "search-information-box destop",
          searchForm ? "active" : ""
        )}
      >
        <div className="main-box">
          <h6 className="search-title">{title}</h6>
          {searchValue === "" ? (
            trendingData.map((item, index) => (
              <SearchItem
                key={item.id}
                searchItem={item}
                isEven={index % 2 === 0}
                formattedCurrentDate={formattedCurrentDate}
              />
            ))
          ) : filteredData.length > 0 ? (
            filteredData.map((item, index) => (
              <SearchItem
                key={item.id}
                searchItem={item}
                isEven={index % 2 === 0}
                formattedCurrentDate={formattedCurrentDate}
              />
            ))
          ) : (
            <EmptySearchResult />
          )}
        </div>
      </div>

      <div
        id="modals"
        className={clsx(
          "modals z-[10000] relative",
          smSearchForm ? "block h-full" : ""
        )}
      >
        <span className="closes" onClick={() => setSmSearchForm(false)}>
          X
        </span>
        <div className="modal-contents">
          <div className="hero-search-bar">
            <form className="hero-form">
              <input
                id="showModalModal"
                type="text"
                placeholder={t("search_deals_placeholder")}
                autoComplete="off"
                name="search"
                ref={searchMobile}
                onFocus={handleSearchFormFocus}
                onClick={handleSearchFormFocus}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </form>
            <div
              // ref={searchModalBoxRef}
              className={clsx(
                "search-information-box mobile",
                smSearchForm ? "active" : ""
              )}
            >
              <div className="main-box">
                <h6 className="search-title">{title}</h6>
                {searchValue === "" ? (
                  trendingData.map((item, index) => (
                    <SearchItem
                      key={item.id}
                      searchItem={item}
                      isEven={index % 2 === 0}
                    />
                  ))
                ) : filteredData.length > 0 ? (
                  filteredData.map((item, index) => (
                    <SearchItem
                      key={item.id}
                      searchItem={item}
                      isEven={index % 2 === 0}
                    />
                  ))
                ) : (
                  <EmptySearchResult />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealSearchBar;
