import React, { useState, useEffect } from "react";
import WhatsappLogo from "../../assets/whatsapp-logo.svg";
import { CustomSelect } from "../home/Home";
import Logo from "../../components/Logo";
import { Link } from "react-router-dom";
import clsx from "clsx";
import AllDealsIcon from "../../assets/all-deals-icon.svg";
import DealsOfTheWeek from "../../components/DealsOfTheWeek";
import DealOverview from "../../components/DealOverview";
import DealSearchBar from "../../components/DealSearchBar";
import { useTranslation } from "react-i18next";
import { supabase } from "../../supabaseClient";
import Icon from "../../components/Icon";
import LocationIcon from "../../assets/image svg/Locatie.svg";
import ArrowDownIcon from "../../assets/image svg/chevron down.svg";
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";

export default function Lifestyle({ language, setLanguage, deals }) {
  const [currentCategory, setCurrentCategory] = useState("all_deals");
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("all_cities");
  const [filteredDeals, setFilteredDeals] = useState(deals);

  useEffect(() => {
    supabase
      .from("LifestyleCategory")
      .select("*")
      .order("index", { ascending: true })
      .then((res) => {
        setCategories(res.data);
      });
  }, []);

  useEffect(() => {
    supabase
      .from("City")
      .select("*")
      .then((res) => {
        setCities(res.data.map((city) => city.name));
      });
  }, []);

  useEffect(() => {
    if (selectedCity === "all_cities") {
      setFilteredDeals(deals);
    } else {
      setFilteredDeals(
        deals.filter((deal) =>
          deal.cities
            ?.split(",")
            .map((c) => c.trim())
            .includes(selectedCity)
        )
      );
    }
  }, [selectedCity, deals]);

  const handleCategoryChange = (category) => {
    setCurrentCategory(category);
  };

  const { t } = useTranslation();

  return (
    <div className="bg-[#12041f] pb-[80px] min-h-[100vh]">
      <div className="hero-section relative">
        <div className="lg:hidden w-full flex items-center justify-center pt-12">
          <div className="flex justify-between w-[90%] items-center">
            <Link to="https://linktr.ee/22nightmember" target="_blank">
              <img
                src={WhatsappLogo}
                className="w-12 h-12"
                alt="Whatsapp logo"
              />
            </Link>
            <Link to="/">
              <Logo />
            </Link>
            <CustomSelect
              options={[
                { value: "nl", label: "🇳🇱 Nederlands" },
                { value: "en", label: "🇺🇸 English" },
              ]}
              onSelect={(value) => {
                setLanguage(value);
              }}
            />
          </div>
        </div>
        <div className="pt-[40px] lg:pt-[80px] lg:pb-[40px] px-[10px] lg:px-[100px]">
          <div className="hero-card rounded-[16px] border-2 p-[10px] lg:p-[40px]">
            <div className="hero-tabs">
              <div
                className={`w-full items-center justify-center text-center ${
                  selectedCity === "all_cities" ? "lg:px-[20%]" : "lg:px-[10%]"
                } block`}
                data-id="tab2"
              >
                <h1
                  className="w-full max-lg:pb-[24px] text-[36px] lg:!text-[64px] text-white leading-[44px] lg:leading-[72px]"
                  id="heading"
                >
                  <div
                    className={`items-center w-full justify-center ${
                      selectedCity === "all_cities" ? "flex flex-col" : "flex flex-row gap-3"
                    }`}
                  >
                    <div>Community</div>
                    <div>benefits</div>
                  </div>
                  {/* Community benefits <br /> */}
                  {selectedCity !== "all_cities" && <>{selectedCity}</>}
                </h1>
              </div>
            </div>

            <div className="hero-search-bar hero-card mt-[64px] rounded-[16px]">
              <DealSearchBar deals={filteredDeals} formattedCurrentDate={""} />
              <div className="lg:px-12 lg:!tabs pt-[22px] pb-[34px]">
                <div className="flex gap-[16px] lg:gap-12 w-full max-lg:!w-full max-lg:flex max-lg:justify-center">
                  {/* Alle deals */}
                  {filteredDeals.length > 0 && (
                    <div
                      className={clsx(
                        "rotterdam-tab hover:cursor-pointer",
                        currentCategory === "all_deals" ? "active" : ""
                      )}
                    >
                      <div
                        className={clsx(
                          "tab-a flex flex-col items-center",
                          currentCategory === "all_deals" ? "active-a" : ""
                        )}
                        data-id="tab2"
                        onClick={() => {
                          handleCategoryChange("all_deals");
                        }}
                      >
                        <img
                          src={AllDealsIcon}
                          alt="roterdam"
                          className="h-12 w-12"
                        />
                        <h5 className="text-white">{t("all_deals")}</h5>
                      </div>
                    </div>
                  )}
                  {categories.map((category) => {
                    if (
                      filteredDeals.filter(
                        (deal) => deal.category_id === category.id
                      ).length === 0
                    ) {
                      return null;
                    }

                    return (
                      <div
                        className={clsx(
                          "rotterdam-tab hover:cursor-pointer",
                          currentCategory === category.id ? "active" : ""
                        )}
                      >
                        <div
                          className={clsx(
                            "tab-a flex flex-col items-center",
                            currentCategory === category.id ? "active-a" : ""
                          )}
                          data-id="tab2"
                          onClick={() => {
                            handleCategoryChange(category.id);
                          }}
                        >
                          <Icon
                            icon={category.icon}
                            alt={category.name}
                            className="h-12 w-12 text-neutral-300"
                          />
                          <h5 className="text-white">{category.name}</h5>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-container lg:mt-[24px]">
        <h2 className="text-white ontdek max-lg:!px-[10px] !mb-[-8px]">
          {t("discover_community_deals")}
        </h2>
        <div className="wrapper max-lg:!px-[10px]">
          <div className="external-dropdown">
            <div className="outer">
              <div className="side filter-date mr-5">
                <Menu>
                  <MenuButton
                    as={Button}
                    className="btn btn-dropdown dropdown-toggle"
                    leftIcon={<img src={LocationIcon} alt="locatie" />}
                    rightIcon={<img src={ArrowDownIcon} alt="arrow down" />}
                  >
                    <span className="selectedOption" id="button1Text">
                      {selectedCity === "all_cities"
                        ? t("all_cities")
                        : selectedCity}
                    </span>
                  </MenuButton>
                  <MenuList
                    className="menu-list"
                    pos="absolute"
                    top="8"
                    left="0"
                  >
                    <MenuOptionGroup
                      className=""
                      defaultValue={selectedCity}
                      type="radio"
                    >
                      <MenuItemOption
                        onClick={() => setSelectedCity("all_cities")}
                        value="all_cities"
                      >
                        {t("all_cities")}
                      </MenuItemOption>
                      {cities.map((city, index) => (
                        <MenuItemOption
                          key={index}
                          onClick={() => setSelectedCity(city)}
                          value={city}
                        >
                          {city}
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="custom-container max-lg:!mt-[24px] lg:!mt-[40px] pb-[80px]">
        {/* Alle deals */}
        {currentCategory === "all_deals" && filteredDeals.length > 0 && (
          <>
            <DealsOfTheWeek
              dealsOfTheWeek={filteredDeals.filter(
                (deal) => deal.dealOfTheWeek === true
              )}
            />
            {categories.map((category) => {
              const categoryDeals = filteredDeals
                .filter((deal) => deal.category_id === category.id)
                .sort((a, b) => a.index - b.index);

              if (categoryDeals.length > 0) {
                return (
                  <DealOverview
                    key={category.id}
                    deals={categoryDeals}
                    title={`${category.name} deals`}
                    isCarousel={true}
                  />
                );
              }
              return null;
            })}
          </>
        )}

        <div className="mb-[40px]">
          {currentCategory !== "all_deals" &&
            filteredDeals.filter(
              (deal) => deal.category_id === currentCategory
            ) && (
              <DealOverview
                deals={filteredDeals
                  .filter((deal) => deal.category_id === currentCategory)
                  .sort((a, b) => a.index - b.index)}
                title={`${
                  categories.find((cat) => cat.id === currentCategory)?.name
                } deals`}
                isCarousel={false}
              />
            )}
        </div>
      </section>
    </div>
  );
}
