import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/nl";

import { useNavigate } from "react-router-dom";
dayjs.extend(utc);
dayjs.extend(timezone);

const EventCard = ({ eventCardData, singleEvent, disableLink = false }) => {
  const navigate = useNavigate();
  const eventClassName = `event-content text-white ${
    !singleEvent ? "doubles" : null
  }`;
  let eventName = eventCardData.name;
  eventName = eventName.charAt(0) + eventName.slice(1);

  const eventTime = dayjs(eventCardData.datetime).locale("nl").format("HH:mm");
  const eventDate = dayjs(eventCardData.datetime).locale("nl").format("D MMM");

  const cityName = eventCardData.city;
  const clubName =
    eventCardData.venue.name.charAt(0).toUpperCase() +
    eventCardData.venue.name.slice(1).toLowerCase();

  const handleItemClick = () => {
    // if (eventCardData.OutofStock['sold-out'] !== true && eventCardData.OutofStock['sold-out'] !== "true") {
    if (!disableLink) {
      navigate(`/single-event?eventId=${eventCardData.id}`, {
        state: eventCardData.id,
      });
    }
    // return null;
  };

  return (
    <div
      className={`${eventClassName} hover:scale-[102%] transition-all `}
      onClick={handleItemClick}
    >
      <a>
        <h5 className="lg:text-[18px] lg:leading-[24px] text-[16px] leading-[22px] text-white font-[700]">
          {eventName}
          {/* {eventTime} */}
        </h5>
        <h6 className="!text-white lg:text-[16px] text-[14px] font-normal leading-[16px]">
          {disableLink && `${eventDate} • `}{eventTime} • {clubName} • {cityName}
        </h6>

        {eventCardData.sold_out === true ||
        eventCardData.sold_out === "true" ? (
          <div className="!rounded-[4px] Sould Out">
            <span className="!text-[14px] !px-[6px] !py-[4px]">Sold out</span>
          </div>
        ) : (
          <div className="flex items-center justify-start gap-2 mt-[14px]">
            <div className="!rounded-[4px] text-white bg-green-600">
              <span className="!text-[14px] !px-[6px] !py-[4px] !bg-transparent min-w-[55px] flex items-center justify-center">
                {eventCardData.currency}{parseFloat(eventCardData.our_price).toFixed(2)}
              </span>
            </div>
            <div className="!rounded-[4px] text-white bg-gray-600">
              <span className="line-through !text-[14px] !px-[6px] !py-[4px] !bg-transparent min-w-[55px] flex items-center justify-center">
                {eventCardData.currency}{parseFloat(eventCardData.original_price).toFixed(2)}
              </span>
            </div>
            {/* <span>{eventTime}</span>
            <span>{age}+</span>
            <div className="tool">
              <span className="gen">{t(category)}</span>{" "}
              <span className="tooltips">
                {" "}
                <InfoSvg />
                {category === "Community ticket" ? (
                  <span className="tooltiptext tooltipText wa Vriendenticket">
                    {t("community_ticket_guarantee")}
                  </span>
                ) : (
                  <span className="tooltiptext tooltipText wa Vriendenticket">
                    {t("guestlist_entry")}
                  </span>
                )}
              </span>
            </div> */}
          </div>
        )}
      </a>
    </div>
  );
};

export default EventCard;
