import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "@chakra-ui/react";

export default function DateInput({ className, dob, setDob }) {
  const { t } = useTranslation();

  const [dobPlaceHolder, setDobPlaceHolder] = useState(t("date_of_birth"));

  const focusDob = () => {
    setDobPlaceHolder("dd-mm-jjjj");
  };

  const blurDob = () => {
    setDobPlaceHolder(t("date_of_birth"));
  };

  const isValidDate = (dateString) => {
    // Check format is correct (dd-mm-yyyy)
    if (!/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
      return false;
    }

    // Parse the date parts
    const [day, month, year] = dateString.split("-").map(Number);

    // Create date object and verify parts match (this handles invalid dates like 31-04-2024)
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  };

  const changeDob = (e) => {
    const value = e.target.value;
    let formattedValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    let placeholder = "dd-mm-jjjj";
    let cursorPosition = formattedValue.length;
    const key = e.nativeEvent.inputType;

    const padWithZero = (number) => {
      return number.toString().padStart(2, "0");
    };

    // Format day (dd)
    if (formattedValue.length >= 2) {
      let day = parseInt(formattedValue.slice(0, 2), 10);

      if (day < 1) {
        day = 1;
      } else if (day > 31) {
        day = 31;
      }

      formattedValue = padWithZero(day) + "-" + formattedValue.slice(2);
      placeholder = formattedValue + placeholder.slice(3);
      cursorPosition = 3;
    }

    // Format month (mm)
    if (formattedValue.length >= 5) {
      let month = parseInt(formattedValue.slice(3, 5), 10);

      if (month < 1) {
        month = 1;
      } else if (month > 12) {
        month = 12;
      }

      formattedValue =
        formattedValue.slice(0, 3) +
        padWithZero(month) +
        "-" +
        formattedValue.slice(5);
      placeholder = placeholder.slice(0, 3) + "-mm-" + placeholder.slice(5);
      cursorPosition = 6;
    }

    // Format year (jjjj)
    if (formattedValue.length >= 10) {
      let year = parseInt(formattedValue.slice(6, 10), 10);

      const minYear = 1930;
      const maxYear = new Date().getFullYear();

      if (year < minYear) {
        year = minYear;
      } else if (year > maxYear) {
        year = maxYear;
      }

      formattedValue =
        formattedValue.slice(0, 6) + year.toString().padStart(4, "0");

      // Validate if the date actually exists
      if (!isValidDate(formattedValue)) {
        // If date is invalid, adjust to last valid date of the month
        const [day, month, year] = formattedValue.split("-").map(Number);
        const lastDayOfMonth = new Date(year, month, 0).getDate();
        formattedValue = `${padWithZero(
          Math.min(day, lastDayOfMonth)
        )}-${padWithZero(month)}-${year}`;
      }
    }

    if (
      key === "deleteContentBackward" &&
      (formattedValue.length === 6 || formattedValue.length === 3)
    ) {
      formattedValue =
        formattedValue.slice(0, cursorPosition - 1) +
        formattedValue.slice(cursorPosition);
      cursorPosition -= 1;
    }

    setDob(formattedValue);
    setDobPlaceHolder(placeholder);
  };

  const extractAge = (date) => {
    const [day, month, year] = date.split("-");
    const birthDate = new Date(`${year}-${month}-${day}`);
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    // console.log('AGE', age)
    return age;
  };

  return (
    <Input
      type="text"
      maxLength="10"
      id="date"
      name="dob"
      value={dob}
      inputMode="numeric"
      className={"sin " + className}
      placeholder={dobPlaceHolder}
      onFocus={focusDob}
      onBlur={blurDob}
      onChange={changeDob}
      autoComplete="off"
    />
  );
}
