import { Link, useLocation, useNavigate } from "react-router-dom";
import Icon from "./Icon";
import { supabase } from "../supabaseClient";
import { useDispatch } from "react-redux";
import { setUser } from "../slices/userReducer";

export default function ProfileSidebar({ user }) {
  const currentPath = useLocation().pathname;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const logout = () => {
    supabase.auth.signOut();
    dispatch(
      setUser({
        loading: false,
        id: "",
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        date_of_birth: "",
        location: "",
        gender: "",
        token: "",
        created_at: "",
        role: "",
        genres: "",
        user_info_id: "",
      })
    );
    navigate("/login");
  };

  return (
    <div className="max-lg:pt-12 flex max-lg:flex-col lg:flex-row items-center">
      <div className="max-lg:w-[90%] lg:w-full">
        <div className="lg:hidden">
          <h3 className="text-[26px] text-white">Profiel</h3>
        </div>
        <Link
          className={`lg:hidden mt-12 flex items-center justify-between ${
            currentPath === "/profile/me" ? "bg-[#180933]" : ""
          }`}
          to="/profile/me"
        >
          <div className="flex items-center justify-start gap-4">
            <div className="p-4 bg-gray-300 rounded-full">
              <Icon icon="heroicons:user" className="text-black h-10 w-10" />
            </div>
            <div>
              <h4 className="text-[16px] leading-[22px] text-white">
                {user.first_name}
              </h4>
              <p className="text-[14px] leading-[20px] text-purple-300">
                Bekijk profiel
              </p>
            </div>
          </div>
          <Icon
            icon="heroicons:chevron-right"
            className="h-10 w-10 text-gray-200"
          />
        </Link>
        <hr className="lg:hidden mt-[30px] border-[#302640]" />
        <div className="mt-[30px]">
          <Link
            className={`max-lg:hidden rounded-lg mt-[12px] flex items-center justify-between ${
              currentPath === "/profile/me" ? "bg-[#D0ADFB20]" : ""
            }`}
            to="/profile/me"
          >
            <div className="flex items-center justify-start max-lg:gap-4">
              <div className="lg:p-6 max-lg:p-4">
                <Icon
                  icon="heroicons:user-circle"
                  className="text-gray-200 h-10 w-10"
                />
              </div>
              <div>
                <h4 className="text-[18px] leading-[22px] text-white">
                  Account
                </h4>
              </div>
            </div>
            <Icon
              icon="heroicons:chevron-right"
              className="lg:hidden h-10 w-10 text-gray-200"
            />
          </Link>
          <Link
            className={`rounded-lg mt-[12px] flex items-center justify-between ${
              currentPath === "/profile/alerts" ? "bg-[#D0ADFB20]" : ""
            }`}
            to="/profile/alerts"
          >
            <div className="flex items-center justify-start max-lg:gap-4">
              <div className="lg:p-6 max-lg:p-4">
                <Icon
                  icon="heroicons:bell-alert"
                  className="text-gray-200 h-10 w-10"
                />
              </div>
              <div>
                <h4 className="text-[18px] leading-[22px] text-white">
                  Ticket alerts
                </h4>
              </div>
            </div>
            <Icon
              icon="heroicons:chevron-right"
              className="lg:hidden h-10 w-10 text-gray-200"
            />
          </Link>
          <Link
            className={`rounded-lg mt-[12px] flex items-center justify-between ${
              currentPath === "/profile/events" ? "bg-[#D0ADFB20]" : ""
            }`}
            to="/profile/events"
          >
            <div className="flex items-center justify-start max-lg:gap-4">
              <div className="lg:p-6 max-lg:p-4">
                <Icon icon="ph:confetti" className="text-gray-200 h-10 w-10" />
              </div>
              <div>
                <h4 className="text-[18px] leading-[22px] text-white">
                  Afgelopen events
                </h4>
              </div>
            </div>
            <Icon
              icon="heroicons:chevron-right"
              className="lg:hidden h-10 w-10 text-gray-200"
            />
          </Link>
          <Link
            to="https://linktr.ee/22nightmember"
            className="mt-[12px] flex items-center justify-between"
            target="_blank"
          >
            <div className="flex items-center justify-start max-lg:gap-4">
              <div className="lg:p-6 max-lg:p-4">
                <Icon
                  icon="heroicons:key"
                  className="text-gray-200 h-10 w-10"
                />
              </div>
              <div>
                <h4 className="text-[18px] leading-[22px] text-white">
                  Invite friends
                </h4>
              </div>
            </div>
            <Icon
              icon="heroicons:chevron-right"
              className="lg:hidden h-10 w-10 text-gray-200"
            />
          </Link>
          <Link
            className="mt-[12px] flex items-center justify-between"
            to="https://wa.me/31617476764"
            target="_blank"
          >
            <div className="flex items-center justify-start max-lg:gap-4">
              <div className="lg:p-6 max-lg:p-4">
                <Icon
                  icon="heroicons:chat-bubble-left-ellipsis"
                  className="text-gray-200 h-10 w-10"
                />
              </div>
              <div>
                <h4 className="text-[18px] leading-[22px] text-white">
                  Support
                </h4>
              </div>
            </div>
            <Icon
              icon="heroicons:chevron-right"
              className="lg:hidden h-10 w-10 text-gray-200"
            />
          </Link>
          <button
            className="mt-[12px] flex items-center justify-between w-full"
            onClick={logout}
          >
            <div className="flex items-center justify-start max-lg:gap-4">
              <div className="lg:p-6 max-lg:p-4">
                <Icon
                  icon="heroicons:arrow-left-on-rectangle"
                  className="text-gray-200 h-10 w-10"
                />
              </div>
              <div>
                <h4 className="text-[18px] leading-[22px] text-white">
                  Logout
                </h4>
              </div>
            </div>
            <Icon
              icon="heroicons:chevron-right"
              className="lg:hidden h-10 w-10 text-gray-200"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
