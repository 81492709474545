import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { defaultIcons } from "../helpers/helpers";
import Icon from "./Icon";

export default function EditLifestyleCategoryModal({
  show,
  setShow,
  create,
  currentCategory,
  setCurrentCategory,
}) {
  const [formData, setFormData] = useState({
    name: "",
    icon: "",
    index: 0,
  });

  const [error, setError] = useState(false);

  useEffect(() => {
    if (!create && currentCategory) {
      setFormData({ ...currentCategory });
    }
  }, [currentCategory, create]);

  const saveCategory = () => {
    if (
      Object.values(formData).some((item, index) => {
        if (item === "") {
          return true;
        }
      })
    ) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    if (create === true) {
      supabase
        .from("LifestyleCategory")
        .insert({ ...formData })
        .then((response) => {
          setShow(false);
        });
    } else {
      supabase
        .from("LifestyleCategory")
        .update({ ...formData })
        .eq("id", currentCategory.id)
        .then((response) => {
          console.log("RES", response);
          setCurrentCategory({
            name: "",
            icon: "",
            index: 0,
          });
          setShow(false);
        });
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto "
        onClose={() => {
          setShow(false);
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full h-screen bg-[#00000040] fixed z-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-7xl sm:w-full sm:p-6">
              <div className="absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-12 w-12" aria-hidden="true" />
                </button>
              </div>
              <div className="mb-3 flex justify-center w-full">
                <div className="flex-col flex items-center w-full">
                  <div className="text-center w-full">
                    <h1 className="text-5xl font-bold text-black w-full">
                      {create ? "Create a new category" : "Edit category"}
                    </h1>
                  </div>
                </div>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Category name</label>
                <input
                  placeholder="Category name"
                  value={formData.name}
                  type="text"
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                />
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">Icon</label>
                <div className="grid grid-cols-4 gap-4 p-4 border-2 border-gray-300 rounded-lg">
                  {defaultIcons.map((icon) => (
                    <button
                      key={icon}
                      type="button"
                      className={`flex items-center justify-center p-4 rounded-lg transition-colors ${
                        formData.icon === icon
                          ? "bg-[#07af71] text-white"
                          : "bg-white text-black hover:bg-gray-100"
                      }`}
                      onClick={() => setFormData({ ...formData, icon: icon })}
                    >
                      <Icon icon={icon} className="h-8 w-8" />
                    </button>
                  ))}
                </div>
              </div>

              <div className="mt-6 flex flex-col justify-start gap-1">
                <label className="text-xl text-black mb-0">
                  Display order (lower numbers appear first)
                </label>
                <input
                  type="text"
                  value={formData.index || 0}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      index: parseInt(e.target.value) || 0,
                    })
                  }
                  className="text-2xl text-black bg-white border-2 border-gray-300 p-4 rounded-lg"
                />
              </div>

              {error && (
                <p className="text-3xl font-semibold mt-5 text-red-500">
                  Please fill in all fields.
                </p>
              )}

              <div className="flex items-center justify-end w-full pt-9">
                <button
                  onClick={() => {
                    saveCategory();
                  }}
                  className="text-white transition-all px-6 lg:px-12 py-3 rounded-lg text-3xl font-semibold hover:bg-[#38c28f] bg-[#07af71]"
                >
                  Save category
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
